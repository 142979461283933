import React from "react";

import { Layout, PageHeader, Button, Tabs } from 'antd';

import { PictureOutlined } from '@ant-design/icons';

import { logOut } from '../context/firebaseConfig'
import '../assets/css/binding.css'

import SearchForm from "../components/serchForm";

const { Content } = Layout;
const { Item } = Tabs;


const Binding = () => {
    return (
        <Layout>
            <PageHeader
            style={{ height: "110px", padding: "0px"}}
            ghost='false'
            title={<img src='/assets/img/logo.jpg' alt='UNPAID' style={{ maxHeight: "100px", marginLeft: '20px'}}/>}
            extra={[
                <div key='banners_fragment_1' className="divLogout" >
                        <Button onClick={() => { logOut(); }} key="logout" 
                            type="primary" style={{marginRight: '15px', margin: '0 auro'}}>Salir</Button> 
                    </div>                     
                ]}
                />
                <Content className="" style={{marginLeft: '20px'}}>
                <Tabs defaultActiveKey="1" >
                    <Item tab={<span><PictureOutlined />Remoto</span>} key={`tabKey_remoto`}>
                        <SearchForm mode='remoto'/>
                    </Item>
                    <Item tab={<span><PictureOutlined />Autohelp</span>} key={`tabKey_autohelp`}>
                        <SearchForm mode='ccar'/>
                    </Item>
                </Tabs>
            </Content>
        </Layout>
    )
}

export default Binding;