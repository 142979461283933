import React, { useEffect, useState } from 'react';
import {CSVLink} from "react-csv"
import { Table, Button, message } from 'antd';

const columns = [
  {
    title: 'Teléfono',
    dataIndex: 'phone',
    width: '30%',
  },
  {
    title: 'Fecha vinculación',
    dataIndex: 'date',
    width: '70%',
  }
];

const MyTable = (props) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setData(props.data);
    setLoading(props.loading);
  }, [props])

  const defaultFooter = () => (
    <div>
      <Button type={data?.length > 0 ? 'primary' : 'disabled'} style={{pointerEvents: data?.length > 0 ? '' : 'none'}}>
        <CSVLink filename={`${props?.filename || data}.csv`} data={data} className="btn btn-primary" 
          onClick={()=>{ message.success("El fichero se ha descargado")}}>
            Export to CSV 
        </CSVLink> 
      </Button>
    </div>
  );

  const tableProps = {
    footer: defaultFooter,
    columns: columns,
    scroll: {
      y: 500,
    }
  }
  return(
    <div style={{maxWidth: '90%', margin: '0 auto'  }}>
      <Table
        {...tableProps}
        dataSource={data}
        loading={loading}
      />
    </div>
);
}
export default MyTable;