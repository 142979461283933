import React, { useState } from 'react';

import { DatePicker } from 'antd';
import { Layout, Button } from 'antd';
import MyTable from './MyTable';

import { getBindings } from '../context/firebaseConfig'

const { RangePicker } = DatePicker;
const { Content } = Layout;

const SearchForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const [ini, setIni] = useState(null);
    const [end, setEnd] = useState(null)
    const [enabled, setEnabled] = useState(false);

    const onChange = (date, dateString) => {
        setIni(dateString[0]);
        setEnd(dateString[1]);
        setEnabled(dateString[0]?.length > 0 && dateString[1]?.length > 0);
    };
/* 
    const demodata = () => {
        let aaaa = [];
        for(var i = 0; i < 100; i++) {
            aaaa.push({phone: `+34${i}000${i}`, date: `Hace: ${i} dias`})
        }
       return aaaa;
    }  */


    // TODO:aquí se hace la consulta con el rango de fechas
    const getData = async () => {
        setLoading(true);
        const data = await getBindings(ini, end, props.mode)
        setData(data);
        // setData(demodata)
        setLoading(false);
    }

    return (
        <Layout style={{ maxWidth: '90%', display: 'flex' }} >
            <Content style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <RangePicker onChange={onChange} />
                <Button key="btn_proton" onClick={()=> getData()} type={enabled ? 'primary' : 'disabled'} style={{marginRight: '15px', pointerEvents: enabled ? '' : 'none'}}>Consultar</Button> 
            </Content>
            <MyTable data={data} loading={loading} />
        </Layout>
    )
}

export default SearchForm;