import React, { useState, useEffect } from "react";
import { Layout, Col, Row } from "antd";
import { Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {
    app, appCC, getAuth, setSessionID, signInWithEmailAndPassword
} from "../context/firebaseConfig";

import { Spin } from "antd";

import { useNavigate } from "react-router-dom";

import '../assets/css/login.css'

const Login = () => {
    const nav = useNavigate();
    const { Footer } = Layout;
    const [error, seterror] = useState('')
    const [auth, setAuth] = useState();
    const [authCC, setAuthCC] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(()=> {
        setAuth(getAuth(app));
        setAuthCC(getAuth(appCC));
    }, []);

    const logins = async e => {
        setLoading(true);
        const { user, password } = e;
        const a = await intoXX(user, password, auth) ;
        const b = await intoXX(user, password, authCC);
        if(a && b) {
            return nav('/bindings');
        } else {
            seterror('Error logeando')
            setLoading(false);
        }
    }

    const intoXX = async (u, p, auth) => {
        try {
            await signInWithEmailAndPassword(auth, u, p)
            await setSessionID({user: auth?.currentUser?.email, sessionID: +new Date()})
            return auth?.currentUser?.accessToken != null;
        } catch(error) {
            console.log(error);                
            seterror(error.message)
            return null;
        }
    }

    return (
        <Layout style={{ height: "90vh" }}>
            <Row justify='center' align='middle' style={{ height: "80vh" }}>
                <Col span={12} style={{ "textAlign": "center"}}>
                    <img src={`/assets/img/logo.jpg`} alt="UNPAID" />
                </Col>
                <Col span={12} style={{ 
                    display: "flex", 
                    "justifyContent": "center"
                    }}>
                    {!loading ? 
                    <div className='d-flex bg-light align-items-center text-center'
                        style={{
                            padding: 24,
                            width: 400
                        }}
                    >
                        {(
                            <Form className="login-form" onFinish={logins} onSubmit={e => e.preventDefault()}>
                                <Form.Item>
                                    <h1 className='text-primary'>Login </h1>
                                </Form.Item>
                                {error? <Form.Item>{error}</Form.Item>:null}
                                <Form.Item
                                    name="user"
                                    rules={[{ required: true, message: 'ERRMSG_FIELD_REQUIRED' }]}
                                >
                                    <Input 
                                        prefix={<UserOutlined  style={{ color: "rgba(0,0,0,.25)" }} /> } 
                                        placeholder='USERNAME'
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message:'ERRMSG_FIELD_REQUIRED' }]}
                                >
                                    <Input.Password
                                        prefix={
                                            <LockOutlined style={{color: "rgba(0,0,0,.25)"}}
                                            />
                                        }
                                        type="password"
                                        placeholder='PASSWORD'
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                        style={{ marginRight: 10 }}
                                    >
                                        Login
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </div>
                    : <Spin />
                                    }
                </Col>
            </Row>
            <Footer className="fixed-bottom d-none d-sm-block" style={{ textAlign: "center" }}>
                Powered by <a href='https://kaps.es'> Kaps Management Group</a> v.230220_1
            </Footer>
        </Layout>
    );
};
export default Login;