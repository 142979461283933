import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { getFirestore, doc, getDoc, updateDoc, collectionGroup, query, where, getDocs } from 'firebase/firestore'
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";

const app = initializeApp({
    apiKey: "AIzaSyABvFLtT4eprFwakTWjKaq0yeuKG3oOd10",
    authDomain: "remoto-2.firebaseapp.com",
    databaseURL: "https://remoto-2.firebaseio.com",
    projectId: "remoto-2",
    appId: "1:421530974832:web:dad01c47cefed514",
});

const appCC = initializeApp({
    apiKey: "AIzaSyDyxyFHydpDOwafNyaL4NhG5UFmYYlycvw",
    authDomain: "caser-cc.firebaseapp.com",
    databaseURL: "https://caser-cc.firebaseio.com",
    projectId: "caser-cc",
    appId: "1:1060857729405:web:eb0d9488e7f85fc1047c14"
}, 'CC');

const RCNAME = 'unpaid';

const logOut = async () => {
    const au = getAuth(app);
    await signOut(au);
}

const getSessionID = async (params) => {
    const { user } = params;
    const db = getFirestore(app);
    const ref = doc( db, `users/`, user);
    const ref2 = await getDoc(ref);
    let ret = ref2.data();
    return ret?.sessionIDs ? ret?.sessionIDs[0] : 0;
}

const setSessionID = async (params) => {
    const { user, sessionID } = params;
    const db = getFirestore(app);
    const ref = doc( db, `users/`, user);
    await updateDoc(ref, {
        "sessionIDs": [sessionID]
    })
    window.localStorage.setItem('sessionID', sessionID);
}

const getRCValue = async (v) => {
    let remoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 1000;
    await fetchAndActivate(remoteConfig);
    const val = getValue(remoteConfig, v || RCNAME) || [];
    return val;
}


const getBindings = async (i, e, m) => {
    const tim = +new Date(`${i} 00:00:00`);
    const tem = +new Date(`${e} 23:59:59`);
    const sap = m === 'remoto' ? app : appCC;
console.log(sap.name)    
    const bindings = query(collectionGroup(getFirestore(sap), 'policies'), 
                        where('deviceAssignTm', '!=', null), 
                        where('deviceAssignTm', '>=', tim), 
                        where('deviceAssignTm', '<=', tem));
    const querySnapshot = await getDocs(bindings);
    let reet = []
    querySnapshot.forEach((doc) => {
        const data = doc.data();
        const dd = new Date(data.deviceAssignTm).toISOString();
        reet.push({date: `${dd.substring(0, dd.indexOf('T'))}`, phone: data.phone})
    });
    return reet;
}


export { 
    app,
    appCC, 
    initializeApp,
    signInWithEmailAndPassword,
    getFirestore,
    getAuth,
    logOut,
    setSessionID,
    getSessionID,
    getRCValue,
    getBindings
}